<template>
  <div>
    <div class="search-bar">
      <a-form-model layout="inline" ref="searchRef" :model="searchForm">

        <a-form-model-item v-if="isCityManage"
            prop="outlets_city_adcode" label="城市市场" name="outlets_city_adcode">
          <a-select v-model="searchForm.outlets_city_adcode" style="width: 100px;" @change="handlerSearch">
            <a-select-option
              v-for="(item, index) in manageCityList"
              :key="index"
              :value="item.adcode"
            >{{ item.name }}</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item prop="name" label="券名">
          <a-input style="width:180px" placeholder="券名称 / 内部名" v-model="searchForm.name" />
        </a-form-model-item>
        <a-form-model-item prop="name" label="串码">
          <a-input style="width:180px" placeholder="请输入串码" v-model="searchForm.exchange_code" />
        </a-form-model-item>        
        <a-form-model-item prop="effective_time" label="活动日期">
          <a-range-picker
            style="width:230px"
            v-model="searchForm.effective_time"
            valueFormat="YYYY-MM-DD"
            @change="handlerSearch"
          />
        </a-form-model-item>
        <a-form-model-item prop="status" label="状态" name="status">
          <a-select v-model="searchForm.status" style="width: 100px;" @change="handlerSearch">
            <a-select-option key="" value="">不限</a-select-option>
            <a-select-option key="1" value="1">正常</a-select-option>
            <a-select-option key="0" value="0">停用</a-select-option>
          </a-select>
        </a-form-model-item>

        <a-form-model-item>
          <a-button type="primary" html-type="submit" @click="handlerSearch">搜索</a-button>
          <a-button @click="handlerResetForm" style="margin-left: 10px">重置</a-button>
        </a-form-model-item>
      </a-form-model>
    </div>
    <div>
      <div class="flex justify-between">
        <span v-if="isCityManage" class="text-lg font-bold p-3">城市优惠券列表</span>
        <span v-else class="text-lg font-bold p-3">优惠券列表</span>
      </div>
      <div class="flex justify-between mr-2" style="margin-left:10px">
        <a-tabs type="card" class="w-full" @change="handlerTypeChange" :activeKey="activeKey">
          <a-tab-pane v-for="item in typeList" :key="item.type_id" :tab="item.type_name"></a-tab-pane>
          <div slot="tabBarExtraContent">
            <a-button class="p-0" type="link" @click="handlerAdd">新建优惠券</a-button>
          </div>
        </a-tabs>
      </div>
    </div>

    <base-table
      ref="couponTableRef"
      id="couponTableID"
      :columnsData="columns"
      :rowKey="rowKey"
      :tableData="tableData"
      :getListFunc="initData"
      :total="total"
      :page.sync="searchForm.page"
      :pageSize.sync="searchForm.page_count"
    >
      <template #withstored="{record}">
        <!-- <span>{{record.stored_value_card_use_together==2 ? "可以" : ""}}</span> -->
        <a-tag v-if="record.stored_value_card_use_together==2" class="mr-0" color="blue">允许</a-tag>
        <a-tag v-else class="mr-0" color="red">不允许</a-tag>
      </template>
      <template #status="{text}">
        <a-tag class="mr-0" :color="text===1? 'green' : 'red' ">{{text==1? '正常' : '已停用'}}</a-tag>
      </template>
      <template #operation="{record}">
        <a-button class="p-0 ml-2 mr-2" type="link" @click="handlerEdit(record)">详情</a-button>
        <a-button class="p-0 ml-2 mr-2" type="link" @click="handlerRecord(record)">记录</a-button>
        <a-button class="p-0 ml-2 mr-2" type="link" @click="handlerViewCode(record)">串码</a-button>
        <a-button class="p-0 ml-2 mr-2" type="link" style="color:red;"
          v-if="record.status === 1" @click="handlerUpdateStatus(record)">停用</a-button>
        <a-button class="p-0 ml-2 mr-2 red" type="link"
          v-else @click="handlerUpdateStatus(record)">恢复</a-button>
      </template>
    </base-table>

    <!-- 串码modal -->
    <a-modal
      v-model="isShowCodeModal"
      width="600px"
      ok-text="关闭"
      @ok="isShowCodeModal=false"
    >
      <a-row align="middle">
        <a-col  :span="6">优惠券官方名称：</a-col>
        <a-col :span="16">{{this.curRow.name}}</a-col>
      </a-row>
      <a-row align="middle" :gutter="[8, 24]">
        <a-col :span="6">新增串码（个）：</a-col>
        <a-col :span="16">
          <a-input-number v-model="code_number" :min=1 style="width:200px; margin-right:20px;" allowClear  placeholder="请输入新增个数"></a-input-number>
          <a-button @click="doCreate" type="primary">生成串码</a-button>
        </a-col>
      </a-row>
      <a-row align="middle" style="margin-bottom:20px;">
        <a-col :span="6">已生成串码：</a-col>
        <a-col :span="16"><a-button @click="handlerOutput" type="primary">导出全部串码</a-button></a-col>
      </a-row>
      <base-table
        ref="couponCodeTable"
        rowKey="stock_id"
        :columnsData="columnsCode"
        :tableData="tableDataCode"
      >
        <template #operation="{record}">
          <a-button type="link" @click="handlerOutput(record)">导出</a-button>
        </template>
      </base-table>
    </a-modal>
  </div>
</template>

<script>
import pageData from "./columns"
import { getList, updateStatus, couponStockGetList, couponStockCreate, couponStockCodeExport } from "@/api/activity/coupon.js"
import { 
  getMarketCityList,
  isCityManage,
} from '@/utils/authData.js'

export default {
  data() {
    return {
      ...pageData,
      activeKey: 0,
      wrapperCol: { span: 20 },
      labelCol: { span: 4 },
      total: 0,
      searchForm: {
        admin_type: 1, // 写死 普通类发票（区别于财务类）
        outlets_city_adcode: "",
        name: "",
        exchange_code: "",
        effective_time: [],
        effective_time_end: "",
        effective_time_start: "",
        date_type: 0,
        status: "1",
        page: 1,
        page_count: 20,
      },
      tableData: [],
      typeList: [
        { type_id: 0, type_name: "全部" },
        { type_id: 1, type_name: "未开始" },
        { type_id: 2, type_name: "进行中" },
        { type_id: 3, type_name: "已结束" },
      ],

      isCityManage: isCityManage(),
      manageCityList: [],

      isShowCodeModal: false,
      curRow: {},
      columnsCode: [
        {
          title: "生成批次",
          dataIndex: "stock_name",
          align: "center",
          width: 80,
        },
        {
          title: "生成时间",
          dataIndex: "add_time",
          align: "center",
        },
        {
          title: "串码数量",
          dataIndex: "code_number",
          align: "center",
          width: 80,
        },
        {
          title: "已领取数量",
          dataIndex: "exchange_number",
          align: "center",
          width: 100,
        },
        {
          title: "操作",
          align: "center",
          width: 90,
          slots: {
            customRender: "operation",
          }
        }
      ],
      tableDataCode: [],
      code_number: ''
    }
  },
  async mounted() {
    this.manageCityList = await getMarketCityList(true)
    if(this.isCityManage){
      this.searchForm.outlets_city_adcode = this.manageCityList[0].adcode
      this.columns = this.columns.filter(el=>el.dataIndex!="withstored")
    }
    this.initData()
  },
  methods: {
    async initData() {
      const params = this.searchForm
      if (this.searchForm.effective_time.length > 0) {
        params.effective_time_start = this.searchForm.effective_time[0]
        params.effective_time_end = this.searchForm.effective_time[1]
      }else{
        params.effective_time_start = ""
        params.effective_time_end = ""
      }
      const { data, code } = await getList(params)
      if (code === 0) {
        this.tableData = data.list
        this.total = data.total_count
      }
    },

    // 分类切换
    handlerTypeChange(e) {
      this.activeKey = e
      this.searchForm.date_type = e
      this.searchForm.page = 1
      this.initData()
    },
    // 搜索
    handlerSearch() {
      this.searchForm.page = 1
      this.initData()
    },
    // 重置
    handlerResetForm() {
      this.searchForm.name = ""
      this.searchForm.effective_time_end = ""
      this.searchForm.effective_time_start = ""
      this.searchForm.date_type = 0
      this.searchForm.page = 1
      this.searchForm.exchange_code = ""
      this.$refs.searchRef.resetFields()
      this.initData()
    },
    // 新增
    handlerAdd() {
      this.$router.push({
        path: "coupon-add", 
        query: {
          admin_type: this.searchForm.admin_type 
        }
      })
    },
    handlerEdit(row) {
      this.$router.push({ path: "/coupon-detail", query: { id: row.id } })
    },
    // 修改状态
    handlerUpdateStatus(row) {
      updateStatus({
        id: row.id,
        status: row.status === 1 ? 0 : 1,
      }).then((res) => {
        if (res.code === 0) {
          this.$message.success(`${row.status === 1 ? "已停用" : "已恢复"}`)
          this.initData()
        }
      })
    },
    handlerRecord(row){
      const newPage = this.$router.resolve({
        name: "coupon-record",
        query: { 
          coupon_id: row.id,
        },
      });
      window.open(newPage.href, "_blank");
    },
    async handlerViewCode(row) {
      this.isShowCodeModal = true
      this.curRow = row

      const params = {
        coupon_id: this.curRow.id
      }
      const { data, code } = await couponStockGetList(params)
      if (code === 0) {
        this.tableDataCode = data.list
      }
    },
    async doCreate() {
      const params = {
        coupon_id: this.curRow.id,
        code_number: this.code_number
      }
      if(!this.code_number) {
        this.$message.error('新增个数不能为空')
        return
      }
      const { code } = await couponStockCreate(params)
      if (code === 0) {
        this.$message.success('生成串码成功')
        this.handlerViewCode(this.curRow)
      }
    },
    //导出
    handlerOutput(row) {
      let params = {}
      if(row.stock_id) {
        params.stock_id = row.stock_id
      }else {
        params.coupon_id = this.curRow.id
      }
			
      couponStockCodeExport(params)
    }
  },
}
</script>

<style>
</style>