import {formatGoodsType} from '@/utils/type'
export const rowKey = "id";
export const columns = [
  {
    title: "分类",
    align: "center",
    width: 70,
    dataIndex: "belong",
    slots: {
      customRender: 'belong'
    },
    slotsType: 'format',
    slotsFunc: (val) => formatBelong(val)
  },
  {
    title: "类型",
    align: "center",
    width: 80,
    dataIndex: "type",
    slots: {
      customRender: 'type'
    },
    slotsType: 'format',
    slotsFunc: (val) => formatType(val)
  },
  {
    title: "可用区域",
    align: "center",
    width: 140,
    dataIndex: "outlets_city_name",
    slots: {
      customRender: 'outlets_city_name'
    },
    slotsType: 'all',
    slotsFunc: (val) => {
      const city = val.outlets_city_name || "全国"
      return city+"，"+formatScene(val.use_scene)
    }
  },
  // {
  //   title: "使用城市",
  //   align: "center",
  //   width: 90,
  //   dataIndex: "outlets_city_name",
  // },
  {
    title: "优惠券名称",
    align: "center",
    dataIndex: "name",
  },
  {
    title: "内部名",
    align: "center",
    dataIndex: "name_interior",
  },
  // {
  //   title: "有效期开始",
  //   align: "center",
  //   // width: 170,
  //   dataIndex: "start_time",
  // },
  // {
  //   title: "有效期结束",
  //   align: "center",
  //   // width: 170,
  //   dataIndex: "end_time",
  // },
  {
    title: "有效期",
    align: "center",
    dataIndex: "start_time",
    slots: {
      customRender: 'start_time'
    },
    slotsType: 'all',
    slotsFunc: (row) => {
      return String(row.start_time).split(" ")[0] + " ~ " + String(row.end_time).split(" ")[0]
    }
  },
  {
    title: "面额",
    align: "center",
    width: 70,
    dataIndex: "reduce_cost",
  },
  {
    title: "使用门槛",
    align: "center",
    width: 80,
    dataIndex: "least_cost",
  },
  {
    title: "和提货卡同时使用",
    align: "center",
    width: 80,
    dataIndex: "withstored",
    slots: {
      customRender: 'withstored'
    },
  },
  {
    title: "状态",
    align: "center",
    // width: "8%",
    width: 60,
    dataIndex: "status",
    slots: {
      customRender: 'status'
    },
  },
  {
    title: "操作",
    dataIndex: "operation",
    width: 200,
    // width: "13%",
    align: "center",
    slots: {
      customRender: "operation",
    },
  },
];

export const goodsColumn = [
  // {
  //   title: "商品编码",
  //   dataIndex: "goods_number",
  //   align: "center",
  // },
  {
    title: "商品名称",
    dataIndex: "goods_name",
    align: "center",
  },
  {
    title: "产品属性",
    dataIndex: "goods_type",
    align: "center",
    width: 90,
    slots: {
      customRender: "type",
    },
    slotsType: "format",
    slotsFunc: (val) => formatGoodsType(val),
  },
  {
    title: "操作",
    dataIndex: "operation",
    align: "center",
    width: 120,
    slots: {
      customRender: "operation",
    },
  },
]



export const couponType = {
  1:'普通券',
  2:'生日券',
  3:'补偿专用'
}
export function formatType (val) {
  return couponType[val]
}

export const couponBelong = {
  1:'商城券',
  2:'城市券',
}
export function formatBelong (val) {
  return couponBelong[val]
}

export const couponScene = {
  1: '线上',
  2: '线下',
  3: '线上&线下',
}
export function formatScene (val) {
  return couponScene[val]
}

export default {
  rowKey,
  columns,
};
